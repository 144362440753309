.sectionHero {
	@apply pb-[50px];
}

.heroWrapper {
	@apply max-w-[1300px] mx-auto px-6;
	@apply md:grid md:grid-cols-[1.3fr,_1fr] md:gap-[32px] md:items-center;
	@apply lg:gap-[48px] lg:grid-cols-[1.2fr,_1fr] lg:px-5;
}

.heroTextBox h1 {
	@apply text-[34px] leading-[120%] font-jakarta font-bold;
	@apply xsm:text-[38px];
	@apply msm:text-[50px];
	@apply lg:text-[60px];
}

.heroTextBox > p {
	@apply mt-[15px] text-[20px] leading-normal;
	@apply msm:text-[24px];
}

.heroCtaContainer {
	@apply mt-[30px] flex flex-col items-start justify-center max-w-max gap-[8px];
	@apply md:mt-[20px] ssm:flex-row ssm:items-center ssm:justify-start ssm:gap-[16px];
	@apply lg:mt-[40px];
}

.heroCtaContainer a,
.heroCtaContainer button {
	@apply inline-block text-[24px] font-jakarta font-semibold;
	@apply ssm:text-[20px];
	@apply sm:text-[28px];
	@apply md:text-[20px];
	@apply lg:text-[24px];
}

.heroCta {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 100px;
	border-bottom-right-radius: 100px;
	@apply w-auto py-[20px] px-[30px] text-black hover:bg-white;
	@apply focus:bg-white;
	@apply ssm:rounded-full;
	transition: background-color 0.3s;
}

.heroLogin {
	@apply w-auto rounded-full text-white font-semibold;
	@apply ssm:rounded-full ssm:py-[20px] ssm:px-[30px] ssm:border-[2px] ssm:border-solid ssm:border-white ssm:text-white;
	@apply ssm:hover:bg-white ssm:hover:text-black;
	transition: background-color 0.3s, color 0.3s;
}

.heroCta {
	@apply bg-primary;
}

.heroUsersContainer {
	@apply mt-[30px];
	@apply sm:mt-[40px];
}
.heroUsersHeading {
	@apply text-[20px] text-primary uppercase font-bold tracking-wide;
	@apply ssm:text-[24px] ssm:tracking-wider;
}

.heroUsersDescription {
	@apply text-[16px] leading-normal;
	@apply ssm:text-[20px];
}

.heroUsersImagesContainer {
	@apply flex gap-[22px] w-max bg-primary rounded-[50px] p-[10px] mt-[15px];
}

.heroUsersImagesContainer img {
	@apply flex;
}

.heroUsersImages img,
.heroUsersLink {
	@apply inline-block w-[60px] h-[60px] rounded-full;
}

.heroUsersImages img {
	@apply border-[2px];
}

.heroUsersImages img:nth-child(2),
.heroUsersImages img:nth-child(3) {
	@apply -ml-[25px];
}

.heroUsersLink {
	@apply bg-black p-[8px];
}

.heroUsersLinkImageContainer {
	@apply relative w-full h-full;
}

.heroImageBox {
	@apply min-h-full mt-[30px] relative aspect-[3/4]  rounded-3xl overflow-hidden;
	@apply md:mt-0 md:aspect-auto;
	@apply lg:aspect-[3/4] lg:mr-[40px];
}

.heroImageBox img {
	@apply object-cover;
}
