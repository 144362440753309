.formContentWrapper {
	@apply ssm:px-[10px] min-w-full;
}

.formCard {
	@apply z-[1] py-[30px] px-[20px] bg-eerieBlack-light rounded-[20px];
	@apply ssm:py-[50px];
	@apply md:px-[35px];
	@apply lg:px-[50px];
}

.formCard fieldset {
	@apply font-semibold text-[16px] mb-[15px];
}

.formFirstInputs {
	@apply grid;
	@apply ssm:grid-cols-2 ssm:auto-cols-fr ssm:gap-x-[16px];
}

.formCard input,
.formCard textarea {
	@apply py-[12px] px-[12px] mb-[24px] w-full bg-white-100 text-white rounded-[12px] text-[15px];
	@apply hover:bg-white-118 focus:bg-white-118;
	@apply ssm:text-[14px];
	@apply msm:text-[16px];
	@apply lg:text-[18px] md:px-[16px] md:py-[18px];
	transition: color 0.3s, background-color 0.3s;
}

.formCard textarea {
	@apply pb-[70px] resize-none;
}

.formCard input::placeholder,
.formCard textarea::placeholder {
	@apply text-balance;
}
.formCard button {
	@apply py-[20px] px-[20px] text-black rounded-full bg-primary text-[18px] font-bold capitalize min-w-full;
	@apply xsm:px-[30px];
	@apply hover:bg-white focus:bg-white;
	@apply md:text-[20px];
	@apply lg:text-[22px];
	transition: background-color 0.3s;
}

.formCard .PhoneInputCountry {
	@apply h-[calc(100%-24px)];
}

.formFirstInputs :global(.PhoneInputCountry) {
	@apply h-[calc(100%-24px)];
}

.formFirstInputs :global(.PhoneInputCountrySelect) {
	appearance: none; /* remove default appearance set by the browser */
	outline: none; /* remove the outline shown on focus */
	cursor: pointer; /* change the mouse cursor icon */
	@apply bg-eerieBlack-light text-white-800 border-[1px] border-solid border-primary;
}

.formFirstInputs :global(.PhoneInputCountrySelect):focus {
	@apply border-primary;
}

.formCard fieldset:disabled input,
.formCard fieldset:disabled textarea {
	@apply text-white-800 bg-white-50;
}

.formCard fieldset:disabled button {
	@apply bg-white-100 text-white-400;
}
